import React, { useState }  from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  const [formData, setFormData] = useState({
    vezeteknev: '',
    keresztnev: '',
    email: '',
    cegnev: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Discord Webhook URL
    const webhookUrl = 'https://discord.com/api/webhooks/1175333526707646485/wnjMyzYs4hLfRHPzEVERb3Zcp_ahy6JAwaQkjjcHqhSe3bVcqzxdaXDrZ8LyordfGKW1';

    // Felépítjük az üzenetet
    const message = {
      content: 'hehe:',
      embeds: [
        {
          title: 'Új megrendelés',
          fields: [
            { name: 'Vezetéknév', value: formData.vezeteknev, inline: true },
            { name: 'Keresztnév', value: formData.keresztnev, inline: true },
            { name: 'E-mail', value: formData.email, inline: true },
            { name: 'Cég név', value: formData.cegnev, inline: true },
          ],
        },
      ],
    };

    // Elküldjük az üzenetet a Discord Webhook-ra
    try {
      if(formData.vezeteknev && formData.keresztnev && formData.email){
        const response = await fetch(webhookUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
        });
      }
    } catch (error) {
    }
  };
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative overflow-hidden'>
          <div className='container px-4 mx-auto'></div>
          <div className='relative pt-20 lg:pt-28'>
            <div className='relative z-10 container px-4 mx-auto'>
              <div className='relative mb-24 text-center md:max-w-4xl mx-auto'>
                <img
                  className='absolute top-44 -left-36'
                  src='assets/images/headers/star2.svg'
                  alt=''
                />
                <img
                  className='absolute top-10 -right-36'
                  src='assets/images/headers/star2.svg'
                  alt=''
                />
                <span className='inline-block mb-2.5 text-sm text-green-400 font-medium tracking-tighter'>
                  drdev.hu
                </span>
                <h1 className='mb-10 text-7xl lg:text-8xl xl:text-10xl text-white tracking-tighter'>
                  Elkészítjük álmaid alkalmazását
                </h1>
                <a
                  className='inline-block px-8 py-4 tracking-tighter bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300'
                  href='#kapcsolat'
                >
                  Kapcsolat
                </a>
              </div>
              <div className='relative max-w-max mx-auto'>
                <img
                  className='absolute top-7 -right-64'
                  src='assets/images/headers/star.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
          <img
            className='absolute top-0 left-48'
            src='assets/images/headers/layer-blur.svg'
            alt=''
          />
          <img
            className='absolute bottom-0 right-0'
            src='assets/images/headers/lines2.svg'
            alt=''
          />
        </section>
        <section className='relative py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mb-20 md:max-w-xl text-center mx-auto'>
              <span className='inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter'>
                drdev.hu
              </span>
              <h2 className='text-7xl lg:text-8xl text-white tracking-tighter-xl'>
                Amivel foglalkozunk
              </h2>
            </div>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <a
                    className='inline-block mb-6 px-5 py-3 text-sm text-white hover:text-black font-medium hover:bg-green-500 tracking-tight border-2 border-white hover:border-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-200'
                    href='#kapcsolat'
                  >
                    Kapcsolat
                  </a>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Web fejlesztés
                  </h3>
                  <p className='text-white'>
                    A webfejlesztés a digitális jelenlét kiépítésének folyamata
                    a világhálón. Szakértő csapatunk segítségével modern és
                    felhasználóbarát weboldalakat készítünk, alkalmazva a
                    legújabb technológiákat, mint például Angular, Nestjs és
                    MongoDB. Az egyedi tervezés és a funkcionalitás
                    összehangolása révén célunk, hogy vállalkozásod online
                    jelenléte kiemelkedő legyen.
                  </p>
                  <a className='inline-block' href='#'></a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <a
                    className='inline-block mb-6 px-5 py-3 text-sm text-white hover:text-black font-medium hover:bg-green-500 tracking-tight border-2 border-white hover:border-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-200'
                    href='#kapcsolat'
                  >
                    Kapcsolat
                  </a>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Mobil fejlesztés
                  </h3>
                  <p className='text-white'>
                    A mobil alkalmazásfejlesztés révén lehetőséget teremtünk
                    vállalkozásodnak, hogy elérje a mobil eszközöket használó
                    szélesebb közönséget. Szakértő fejlesztőink iOS és Android
                    platformokon egyaránt otthonosan mozognak, és
                    alkalmazásaikat a felhasználói élmény és a funkcionalitás
                    maximális kombinációjával tervezik meg. Legyen szó akár egy
                    egyszerű alkalmazásról vagy egy komplex mobilalkalmazásról,
                    mi gondoskodunk a hatékony kivitelezésről.
                  </p>
                  <a className='inline-block' href='#'></a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <a
                    className='inline-block mb-6 px-5 py-3 text-sm text-white hover:text-black font-medium hover:bg-green-500 tracking-tight border-2 border-white hover:border-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-200'
                    href='#kapcsolat'
                  >
                    Kapcsolat
                  </a>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Hirdetés kezelés
                  </h3>
                  <p className='text-white'>
                    A hirdetéskezelés segít maximalizálni online jelenléted
                    hatékonyságát és láthatóságát. Tapasztalt csapatunk
                    gondoskodik arról, hogy hirdetéseid célzottak és hatékonyak
                    legyenek. Kezeljük a Google Ads, Facebook Ads és egyéb
                    online hirdetési platformok kampányait, optimalizálva azokat
                    a célközönség pontos meghatározása és a hirdetések kreatív
                    összeállítása révén. Célunk, hogy segítsünk növelni
                    vállalkozásod láthatóságát és online sikereit.
                  </p>
                  <a className='inline-block' href='#'></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-20 pb-24 bg-blueGray-950'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <span className='inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter'>
                drdev.hu
              </span>
              <h2 className='mb-6 text-7xl lg:text-8xl text-white tracking-8xl md:max-w-md mx-auto'>
                Legfrissebb munkáink
              </h2>
              <p className='mb-20 text-gray-300 md:max-w-md mx-auto'>
                Tekintsd meg legfrissebben elkészített munkáinkat
              </p>
            </div>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
                <div className='relative mb-8 overflow-hidden rounded-5xl'>
                  <img
                    className='w-full transform hover:scale-125 transition duration-1000'
                    src='assets/images/cards/bg-image1.png'
                    alt=''
                  />
                  <div className='absolute bottom-0 left-0 w-full bg-gradient-card p-8'>
                    <a
                      className='group inline-block max-w-sm'
                      href='http://tabiofficial.hu'
                    >
                      <h3 className='mb-4 text-3xl text-white tracking-3xl hover:underline'>
                        tabiofficial.hu
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
                <div className='relative mb-8 overflow-hidden rounded-5xl'>
                  <img
                    className='w-full transform hover:scale-125 transition duration-1000'
                    src='assets/images/cards/bg-image2.png'
                    alt=''
                  />
                  <div className='absolute bottom-0 left-0 w-full bg-gradient-card p-8'>
                    <a
                      className='group inline-block max-w-sm'
                      href='http://revolutionroleplay.hu'
                    >
                      <h3 className='mb-4 text-3xl text-white tracking-3xl hover:underline'>
                        revolutionroleplay.hu
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
                <div className='relative mb-8 overflow-hidden rounded-5xl'>
                  <img
                    className='w-full transform hover:scale-125 transition duration-1000'
                    src='assets/images/cards/bg-image3.png'
                    alt=''
                  />
                  <div className='absolute bottom-0 left-0 w-full bg-gradient-card p-8'>
                    <a
                      className='group inline-block max-w-sm'
                      href='http://sdrp.hu'
                    >
                      <h3 className='mb-4 text-3xl text-white tracking-3xl hover:underline'>
                        sdrp.hu
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mb-20 md:max-w-xl text-center mx-auto'>
              <span className='inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter'>
                drdev.hu
              </span>
              <h2 className='text-7xl lg:text-8xl text-white tracking-tighter-xl'>
                Eszközök
              </h2>
            </div>
            <div className='relative mb-10 py-20 px-16 bg-gradient-radial-dark overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl'>
              <div className='max-w-6xl mx-auto'>
                <div className='relative z-10 flex flex-wrap items-center -m-8'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='max-w-md mx-auto text-center'>
                      <h2 className='mb-6 text-7xl text-white tracking-tighter-xl'>
                        Hirdetés kezelés
                      </h2>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                  <div className='mb-14 max-w-sm mx-auto'>
                    <div className='flex flex-wrap justify-center'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/photoshop-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/google-analytics-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/imovie-movies-film-entertainment-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/pexels-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/insights-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/google-ads-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='assets/images/features/bg-gray.png'
                alt=''
              />
            </div>
            <div className='flex flex-wrap -m-5'>
              <div className='w-full md:w-1/2 p-5'>
                <div className='relative px-16 pt-14 pb-16 h-full bg-gradient-radial-dark overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl'>
                <div className='mb-14 max-w-sm mx-auto'>
                    <div className='flex flex-wrap justify-center'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/figma.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/visual-studio-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/ionic-icon-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/mongodb-svgrepo-com-svg-1700255759477.webp'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/mysql-logo-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/nestjs-icon.svg'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='relative z-10 max-w-sm text-center mx-auto'>
                    <h2 className='mb-6 text-7xl text-white tracking-tighter'>
                      Mobil fejlesztés
                    </h2>
                  </div>
                  <img
                    className='absolute bottom-0 right-0'
                    src='assets/images/features/bg-gray-2.png'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-full md:w-1/2 p-5'>
                <div className='relative px-16 pt-14 pb-16 h-full bg-gradient-radial-dark overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl'>
                  <div className='mb-14 max-w-sm mx-auto'>
                    <div className='flex flex-wrap justify-center'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/figma.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/Visual-Studio-Code-1-35-icon-svg-1700255356921.webp'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/nestjs-icon.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/mongodb-svgrepo-com-svg-1700255759477.webp'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/mysql-logo-svgrepo-com.svg'
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-24 h-24 bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-5xl'>
                          <img
                            src='images/angular-svgrepo-com-svg-1700255795573.webp'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='relative z-10 max-w-sm text-center mx-auto'>
                    <h2 className='mb-6 text-7xl text-white tracking-tighter'>
                      Web fejlesztés
                    </h2>
                  </div>
                  <img
                    className='absolute bottom-0 right-0'
                    src='assets/images/features/bg-gray-2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mb-16 md:max-w-2xl text-center mx-auto'>
              <span className='inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter'>
                drdev.hu
              </span>
              <h2 className='text-7xl lg:text-8xl text-white tracking-7xl lg:tracking-8xl'>
                Gyakori kérdések
              </h2>
            </div>
            <div className='flex flex-wrap -m-5'>
              <div className='w-full md:w-1/2 lg:w-1/3 p-5'>
                <h3 className='mb-4 text-2xl text-white tracking-2xl'>
                  Mennyi időt vesz igénybe egy weboldal elkészítése?
                </h3>
                <p className='text-white text-opacity-60'>
                  Az időtartam nagyban függ a projekt összetettségétől, a
                  tervezési és fejlesztési igényektől, valamint az elvárt
                  funkcionalitástól. Általában egy átlagos méretű weboldal
                  elkészítése 4-8 héttől akár több hónapig is eltart lehet. Az
                  időtartamot az ügyféllel való egyeztetés után lehet pontosan
                  meghatározni.
                </p>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-5'>
                <h3 className='mb-4 text-2xl text-white tracking-2xl'>
                  Milyen szolgáltatásokat kínálhatok a weboldalamon?
                </h3>
                <p className='text-white text-opacity-60'>
                  A weboldalakon számos szolgáltatást lehet kínálni, attól
                  függően, hogy az adott oldal célja és tevékenysége milyen
                  területre fókuszál.
                </p>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-5'>
                <h3 className='mb-4 text-2xl text-white tracking-2xl'>
                  Mennyibe kerül egy alkalmazás elkészítése?
                </h3>
                <p className='text-white text-opacity-60'>
                  Az árak változóak és függnek a projekt összetettségétől, a
                  tervezési és fejlesztési igényektől, valamint az elvárt
                  funkcionalitástól. Az árajánlatot az ügyféllel való egyeztetés
                  után lehet pontosan meghatározni.
                </p>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-5'>
                <h3 className='mb-4 text-2xl text-white tracking-2xl'>
                  Mi a különbség a frontend és backend fejlesztés között?
                </h3>
                <p className='text-white text-opacity-60'>
                  A frontend és a backend fejlesztés összekapcsolódik, hogy egy
                  teljes webalkalmazást hozzon létre. A frontend a felhasználói
                  élményt biztosítja, míg a backend a működést és az adatok
                  tárolását és kezelését végzi.
                </p>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-5'>
                <h3 className='mb-4 text-2xl text-white tracking-2xl'>
                  Milyen platformokra fejlesztünk alkalmazásokat?
                </h3>
                <p className='text-white text-opacity-60'>
                  Fejlesztéseink során támogatjuk a mobil alkalmazásokat iOS és
                  Android platformokon egyaránt
                </p>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 p-5'>
                <h3 className='mb-4 text-2xl text-white tracking-2xl'>
                  Milyen típusú online hirdetéseket kezeltek?
                </h3>
                <p className='text-white text-opacity-60'>
                  Google Ads, Facebook Ads és más online hirdetési platformok
                  hirdetéseit. Az cél az, hogy hatékony kampányokat hozzunk
                  létre a célközönség elérésére és az ügyfél célcsoportjához
                  illeszkedően.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='py-14 bg-gradient-radial-dark rounded-6xl'>
              <div className='md:max-w-2xl mx-auto text-center px-4'>
                <span className='inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter'>
                  drdev. hu
                </span>
                <h2 className='mb-6 text-5xl text-white tracking-5xl'>
                  Bármikor elérhetsz
                </h2>
                <p className='mb-12 text-gray-300 max-w-lg mx-auto'>
                  Discord szerverünkön 0-24 elérhetőek vagyunk, ha gyorsan
                  szeretnél elérni minket.
                </p>
                <a
                  className='inline-block px-14 py-4 font-medium tracking-2xl border-2 border-green-400 bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300'
                  href='https://discord.gg/drdev'
                >
                  Disocrd
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-20 overflow-hidden' id='kapcsolat'>
          <div className='container px-4 mx-auto'>
            <div className='md:max-w-4xl text-center mx-auto'>
              <span className='inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter'>
                drdev.hu
              </span>
              <h2 className='mb-8 text-7xl lg:text-8xl text-white tracking-7xl lg:tracking-8xl'>
                Lépj te is velünk kapcsolatba
              </h2>
              <p className='mb-20 text-gray-300'>
                Vedd fel velünk másodpercek alatt a kapcsolatot és mi napokon
                belül válaszolunk.
              </p>
              <div className='flex flex-wrap -m-2 mb-10'>
                <div className='w-full md:w-1/2 p-2'>
                  <div className='mb-2 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl'>
                    <input
                      className='pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent'
                      type='text'
                      placeholder='Vezetékneved'
                      name='vezeteknev'
                      onChange={handleChange}
                      id=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-2'>
                  <div className='mb-2 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl'>
                    <input
                      className='pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent'
                      type='text'
                      placeholder='Keresztneved'
                      name='keresztnev'
                      onChange={handleChange}
                      id=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-2'>
                  <div className='mb-2 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl'>
                    <input
                      className='pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent'
                      type='text'
                      placeholder='E-mail'
                      name='email'
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-2'>
                  <div className='mb-2 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl'>
                    <input
                      className='pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent'
                      type='text'
                      placeholder='Cég név'
                      name='cegnev'
                      onChange={handleChange}
                      id=''
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap justify-center max-w-xs mx-auto mb-6'>
                <div className='w-full'>
                  <a
                    className='block px-14 py-4 text-center font-medium tracking-2xl border-2 border-green-400 bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300'
                    onClick={handleSubmit}
                  >
                    Küldés
                  </a>
                </div>
              </div>
              <p className='text-sm text-gray-300 max-w-xs mx-auto'>
                Amennyiben a küldés gombra kattintasz automatikusan elfogadod az
                adatvédelmi tájékoztatónkat.
              </p>
            </div>
          </div>
        </section>
        <section className='py-16 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap items-center justify-between -m-8'>
              <div className='w-auto p-8'>
                <img
                  src='images/drdevlogo-2-png-1700150921318.webp'
                  alt=''
                />
              </div>
              <div className='w-auto p-8'>
                <ul className='flex flex-wrap -m-5'>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='https://discord.gg/drdev'
                    >
                      Discord
                    </a>
                  </li>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='/adatvedelmi.pdf'
                    >
                      Adatvédelmi tájékoztató
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-auto p-8'>
                <div className='relative border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl'>
                  <select
                    className='appearance-none pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-50 outline-none bg-transparent'
                    type='text'
                    placeholder='Company'
                  >
                    <option hidden=''>Hamarosan</option>
                  </select>
                  <svg
                    className='absolute right-8 top-1/2 transform -translate-y-1/2'
                    width={16}
                    height={9}
                    viewBox='0 0 16 9'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.6004 1.4585L9.16706 6.89183C8.52539 7.5335 7.47539 7.5335 6.83372 6.89183L1.40039 1.4585'
                      stroke='white'
                      strokeWidth='1.66667'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

